import React, {useRef} from 'react';
import './App.css';
import {IonPhaser} from '@ion-phaser/react'
import gameConfig from './Game';

export default function App() {
  const gameRef = useRef<HTMLIonPhaserElement|null>(null)

  return (
    <>
      <IonPhaser ref={gameRef} game={gameConfig} initialize={true}/>
    </>
  )
}

