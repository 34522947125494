import {BricksterScene} from "../brickster";
import wrapScene from "../wrapScene";


const initGame = (scene: BricksterScene) => {
  const {storage} = scene;
  storage.gamePlayText = scene.add.text(
    scene.cameras.main.centerX,
    scene.cameras.main.centerY + 65,
    "GAME PLAY", {
      font: "40px Arial",
      color: "#ffffff"
    }
  );
}
const createGame = (scene: BricksterScene) => {
}
const updateGame = (scene: BricksterScene) => {
}
const preloadGame = (scene: BricksterScene) => {
}

const GamePlayScene = wrapScene({
  initGame,
  createGame,
  updateGame,
  preloadGame,
})

export default GamePlayScene;
