import {BricksterScene} from "../brickster";
import {BLOCK_EXPLOSION, blockTypes, createAnimBlock, pickRandomBlock} from "./blocks";

const explosionAnimationName = 'explosionAnim';
const blockAnimationName = 'blockAnim';

const createBlockAnimations = (scene: BricksterScene) => {
  return blockTypes.map((type) => scene.anims.create({
    key: `${blockAnimationName}-${type}`,
    frames: scene.anims.generateFrameNumbers(type, {}), // Sphere here can be any block since they all have 100 frames
    frameRate: 60
  }));
}

const getBlockAnimKey = (texture: string) => {
  if (texture === BLOCK_EXPLOSION) {
    return explosionAnimationName
  }

  return `${blockAnimationName}-${texture}`
}

const createExplosionAnimation = (scene: BricksterScene) => {
  return scene.anims.create({
    key: explosionAnimationName,
    frames: scene.anims.generateFrameNumbers(BLOCK_EXPLOSION, {}),
    frameRate: 60
  });
}

const blockDrop = (scene: BricksterScene, block: Phaser.GameObjects.Sprite) => {
  const x = Math.round(Math.random() * (800 - 48));
  const y = -48 - (Math.random() * 400);
  block.setPosition(x, y);
  scene.tweens.add({
    targets: block,
    y: 600 + 48,
    duration: 2000 + (Math.random() * 2000) >>> 0,
    ease: 'Linear',
    onComplete: function (this: Phaser.Tweens.Tween)  {
      this.remove()
      blockDrop(scene, block)
    }
  });
}

const blockDropAnimation = (scene: BricksterScene) : Array<Phaser.GameObjects.Sprite> => {
  const droppingBlocks = [];
  for (let i = 0; i < 8; i++) {
    const texture = pickRandomBlock();
    const block = createAnimBlock(scene, 0,0, texture);
    droppingBlocks.push(block);
    blockDrop(scene, block);
  }
  return droppingBlocks;
}

export {
  createBlockAnimations,
  createExplosionAnimation,
  explosionAnimationName,
  blockAnimationName,
  getBlockAnimKey,
  blockDropAnimation,
}
