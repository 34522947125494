import {BricksterScene} from "../brickster";
import wrapScene from "../wrapScene";
import LoadingScene from "./Loading";
import MenuScene from "./Menu";
import GamePlayScene from "./GamePlay";
import HowToScene from "./HowTo";
import RankingScene from "./Ranking";
import ScoreScene from "./Score";


const initGame = (scene: BricksterScene) => {
  // Initialize whole game
  scene.storage = {}
  const dataInit = {storage: scene.storage};

  // Initialize all scenes
  scene.scene.add('loading', LoadingScene, false, dataInit)
  scene.scene.add('menu', MenuScene, false, dataInit)
  scene.scene.add('gameplay', GamePlayScene, false, dataInit)
  scene.scene.add('howto', HowToScene, false, dataInit)
  scene.scene.add('ranking', RankingScene, false, dataInit)
  scene.scene.add('score', ScoreScene, false, dataInit)
}
const createGame = (scene: BricksterScene) => {
  scene.scene.start('loading')
}

const updateGame = (scene: BricksterScene) => {
}

const preloadGame = (scene: BricksterScene) => {
  // Preload the loading image
  scene.load.image("loading", 'imgs/backgrounds/loading.jpg')
}

const GameScene = wrapScene({
  initGame,
  createGame,
  updateGame,
  preloadGame,
})

export default GameScene;
