import {BricksterScene} from "../brickster";
import wrapScene from "../wrapScene";
import {blockDropAnimation, createBlockAnimations} from "../objects/animations";

const cleanup = (scene: BricksterScene) => {
  const {storage} = scene
  for (const objk in Object.keys(storage.menu)) {
    const obj = storage.menu[objk];
    if (obj && obj.destroy) {
      obj.destroy();
    }
    if (obj && Array.isArray(obj)) {
      obj.forEach((o: any) => o && o.destroy && o.destroy());
    }
  }
  storage.howto = null;
}

const initGame = (scene: BricksterScene) => {
}
const createGame = (scene: BricksterScene) => {
  const {storage} = scene;
  const {centerX, centerY} = scene.cameras.main;
  const menu: any = {}
  storage.menu = menu;

  storage.menu.currentBg = scene.add.image(
    scene.cameras.main.centerX,
    scene.cameras.main.centerY,
    'background1'
  );

  storage.menu.blockAnim = createBlockAnimations(scene);
  storage.menu.droppingBlocks = blockDropAnimation(scene);
  menu.selector = scene.add.image(0, 0, 'menu_cursor');
  menu.startGame = scene.add.text(
    centerX,
    scene.cameras.main.centerY,
    "Start Game", {
      font: "bold 48px sans-serif",
      color: "#ffffff",
    }
  );

  menu.howtoplay = scene.add.text(
    centerX,
    centerY,
    "How to play", {
      font: "bold 48px sans-serif",
      color: "#ffffff",
      align: 'center'
    }
  );
  menu.ranking = scene.add.text(
    centerX,
    centerY,
    "Ranking", {
      font: "bold 48px sans-serif",
      color: "#ffffff",
      align: 'center'
    }
  );
  const menuHeight = 60;
  menu.howtoplay.setPosition(centerX - (menu.howtoplay.width / 2), centerY - menu.howtoplay.height / 2);
  menu.startGame.setPosition(centerX - (menu.startGame.width / 2), menu.howtoplay.y - menuHeight);
  menu.ranking.setPosition(centerX - (menu.ranking.width / 2), menu.howtoplay.y + menuHeight);

  storage.menu.setOpt = (idx: number) => {
    if (idx < 0) {
      idx = 2;
    }
    if (idx > 2) {
      idx = 0;
    }
    menu.selector.setPosition(menu.howtoplay.x + menu.howtoplay.width / 2, menu.howtoplay.y + 30 + menuHeight * (idx - 1))
    menu.selectedOpt = idx;
  }
  storage.menu.next = () => storage.menu.setOpt(storage.menu.selectedOpt + 1)
  storage.menu.previous = () => storage.menu.setOpt(storage.menu.selectedOpt - 1)
  storage.menu.setOpt(0)

  const enterScene = () => {
    switch (storage.menu.selectedOpt) {
      case 0: // StartGame
        scene.scene.start('gameplay');
        scene.scene.stop('menu');
        break;
      case 1: // How to Play
        scene.scene.start('howto');
        scene.scene.stop('menu');
        break;
      case 2: // Ranking
        scene.scene.start('ranking');
        scene.scene.stop('menu');
        break;
    }
    cleanup(scene);
  }

  scene.input.keyboard.on('keydown-UP', storage.menu.previous);
  scene.input.keyboard.on('keydown-DOWN', storage.menu.next);
  scene.input.keyboard.on('keydown-Z', enterScene)
  scene.input.keyboard.on('keydown-ENTER', enterScene)

}

const updateGame = (scene: BricksterScene) => {}

const preloadGame = (scene: BricksterScene) => {
}

const MenuScene = wrapScene({
  initGame,
  createGame,
  updateGame,
  preloadGame,
})

export default MenuScene;
