import {BricksterScene} from "../brickster";
import wrapScene from "../wrapScene";
import {blockDropAnimation, createBlockAnimations} from "../objects/animations";

const cleanup = (scene: BricksterScene) => {
  const {storage} = scene
  for (const objk in Object.keys(storage.howto)) {
    const obj = storage.howto[objk];
    if (obj && obj.destroy) {
      obj.destroy();
    }
    if (obj && Array.isArray(obj)) {
      obj.forEach((o: any) => o && o.destroy && o.destroy());
    }
  }
  storage.howto = null;
}

const initGame = (scene: BricksterScene) => {
  const {storage} = scene;
  const howto : any = {};
  storage.howto = howto;

  howto.currentBg = scene.add.image(
    scene.cameras.main.centerX,
    scene.cameras.main.centerY,
    'background1'
  );

  howto.blockAnim = createBlockAnimations(scene);
  howto.droppingBlocks = blockDropAnimation(scene);

  howto.baseImage = scene.add.image(
    scene.cameras.main.centerX,
    scene.cameras.main.centerY,
    'howto'
  );

  howto.baseContent = scene.add.image(
    scene.cameras.main.centerX + 90,
    scene.cameras.main.centerY,
    'howto_content'
  );

  howto.grabBoxText = scene.add.text(
    112,
    80,
    "Pegar Caixa", {
      font: "bold 16px sans-serif",
      color: "#000000"
    }
  );
  howto.grabBoxText.setPosition(112 - (howto.grabBoxText.width / 2), 58);

  howto.controlText = scene.add.text(
    112,
    80,
    "Controle", {
      font: "bold 16px sans-serif",
      color: "#000000"
    }
  );
  howto.controlText.setPosition(112 - (howto.controlText.width / 2), 222);

  scene.input.keyboard.on('keydown-Z', () => {
    scene.scene.start('menu');
    scene.scene.stop('ranking');
    cleanup(scene)
  })
}
const createGame = (scene: BricksterScene) => {}
const updateGame = (scene: BricksterScene) => {}
const preloadGame = (scene: BricksterScene) => {}

const HowToScene = wrapScene({
  initGame,
  createGame,
  updateGame,
  preloadGame,
})

export default HowToScene;
