import {BricksterScene} from "../brickster";
import wrapScene from "../wrapScene";
import {blockDropAnimation, createBlockAnimations} from "../objects/animations";

const cleanup = (scene: BricksterScene) => {
  const {storage} = scene
  for (const objk in Object.keys(storage.ranking)) {
    const obj = storage.ranking[objk];
    if (obj && obj.destroy) {
      obj.destroy();
    }
    if (obj && Array.isArray(obj)) {
      obj.forEach((o: any) => o && o.destroy && o.destroy());
    }
  }
  storage.ranking = null;
}

const initGame = (scene: BricksterScene) => {
  const {storage} = scene;
  storage.ranking = {};

  storage.ranking.currentBg = scene.add.image(
    scene.cameras.main.centerX,
    scene.cameras.main.centerY,
    'background1'
  );
  storage.ranking.blockAnim = createBlockAnimations(scene);
  storage.ranking.droppingBlocks = blockDropAnimation(scene);


  storage.ranking.gamePlayText = scene.add.text(
    scene.cameras.main.centerX,
    scene.cameras.main.centerY + 65,
    "RANKING", {
      font: "40px Arial",
      color: "#ffffff"
    }
  );
  scene.input.keyboard.on('keydown-Z', () => {
    scene.scene.start('menu');
    scene.scene.stop('ranking');
    cleanup(scene)
  })
}
const createGame = (scene: BricksterScene) => {}
const updateGame = (scene: BricksterScene) => {}
const preloadGame = (scene: BricksterScene) => {}

const RankingScene = wrapScene({
  initGame,
  createGame,
  updateGame,
  preloadGame,
})

export default RankingScene;
