import Phaser from "phaser";
import GameScene from "./scenes/Game";

const gameConfig: Phaser.Types.Core.GameConfig = {
  width: 800,
  height: 600,
  type: Phaser.AUTO,
  scene: GameScene,
}

export default gameConfig;
