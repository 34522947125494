import {BricksterScene} from "../brickster";
import wrapScene from "../wrapScene";


const initGame = (scene: BricksterScene) => {}
const createGame = (scene: BricksterScene) => {}
const updateGame = (scene: BricksterScene) => {}
const preloadGame = (scene: BricksterScene) => {}

const ScoreScene = wrapScene({
  initGame,
  createGame,
  updateGame,
  preloadGame,
})

export default ScoreScene;
