import Phaser from "phaser";
import {BricksterSceneConfig, BricksterScene} from "./brickster";

// wrapScene converts a BricksterSceneConfig to Phaser Scene Config
function wrapScene(scene: BricksterSceneConfig): Phaser.Types.Scenes.CreateSceneFromObjectConfig {
  return {
    init: function (this: Phaser.Scene, data: any) {
      (this as BricksterScene).storage = data.storage||{};
      scene.initGame(this as BricksterScene)
    },
    create: function (this: Phaser.Scene) {
      scene.createGame(this as BricksterScene)
    },
    update: function (this: Phaser.Scene) {
      scene.updateGame(this as BricksterScene)
    },
    preload: function (this: Phaser.Scene) {
      scene.preloadGame(this as BricksterScene)
    },
    extend: {storage: {}}
  }
}

export default wrapScene;
