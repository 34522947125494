import {BricksterScene} from "../brickster";
import {getBlockAnimKey, explosionAnimationName} from "./animations";

const createAnimBlock = (scene: BricksterScene, x: number, y: number, texture: string): Phaser.GameObjects.Sprite => {
  const sprite = createBlock(scene, x, y, texture);
  sprite.play({key: getBlockAnimKey(texture), repeat: -1});
  return sprite;
}

const createExplosion = (scene: BricksterScene, x: number, y: number): Phaser.GameObjects.Sprite => {
  const sprite = createBlock(scene, x, y, BLOCK_EXPLOSION);
  sprite.play({key: explosionAnimationName, repeat: -1});
  return sprite;
}

const createBlock = (scene: BricksterScene, x: number, y: number, texture: string): Phaser.GameObjects.Sprite => {
  return scene.add.sprite(x, y, texture);
}

const BLOCK_TORUS = 'torus';
const BLOCK_SPHERE = 'sphere';
const BLOCK_TRIANGLE = 'triangle';
const BLOCK_EXPLOSION = 'explosion';

const blockTypes = [
  BLOCK_SPHERE,
  BLOCK_TORUS,
  BLOCK_TRIANGLE
]


const pickRandomBlock = (): string => {
  let p = Math.round(blockTypes.length * Math.random())
  if (p > blockTypes.length - 1) {
    p = blockTypes.length - 1;
  }
  if (p < 0) {
    p = 0;
  }

  return blockTypes[p]
}

export {
  createBlock,
  createAnimBlock,
  createExplosion,
  BLOCK_SPHERE,
  BLOCK_TRIANGLE,
  BLOCK_TORUS,
  BLOCK_EXPLOSION,
  blockTypes,
  pickRandomBlock,
}
