import {BricksterScene} from "../brickster";
import wrapScene from "../wrapScene";

const cleanup = (scene: BricksterScene) => {
  const {storage} = scene
  storage.loading?.loadText?.destroy();
  storage.loading?.currentBg?.destroy();
  storage.loading = null;
}

const initGame = (scene: BricksterScene) => {
  const {storage} = scene;
  const loading : any = {}
  storage.loading = loading;

  scene.cameras.main.setBackgroundColor('#24252A')
  loading.currentBg = scene.add.image(400, 300, 'loading');
  loading.loadText = scene.add.text(
    scene.cameras.main.centerX,
    scene.cameras.main.centerY + 65,
    "Loading", {
      font: "40px Arial",
      color: "#ffffff"
    }
  );
  loading.loadText.setOrigin(0.5);

  scene.load.on('progress', (value: number) => {
    loading.loadText?.setText(`Loading ${(value * 100) >>> 0}%`);
  });
}
const createGame = (scene: BricksterScene) => {
  scene.scene.start('menu');
  cleanup(scene)
}

const updateGame = (scene: BricksterScene) => {}

const preloadGame = (scene: BricksterScene) => {
  // Load textures

  // Load Backgrounds
  scene.load.image("loading", 'imgs/backgrounds/loading.jpg')
  scene.load.image("background1", 'imgs/backgrounds/bg1.jpg')
  scene.load.image("background2", 'imgs/backgrounds/bg2.jpg')
  scene.load.image("background3", 'imgs/backgrounds/bg3.jpg')
  scene.load.spritesheet("sphere", 'imgs/sphere.png', { frameWidth: 48, frameHeight: 48 })
  scene.load.spritesheet("torus", 'imgs/torus.png', { frameWidth: 48, frameHeight: 48 })
  scene.load.spritesheet("triangle", 'imgs/triangle.png', { frameWidth: 48, frameHeight: 48 })
  scene.load.spritesheet("explosion", 'imgs/explosion.png', { frameWidth: 48, frameHeight: 48 })

  // Load other textures
  scene.load.image(`arrow_left`, `imgs/arrow_left.png`);
  scene.load.image(`arrow_right`, `imgs/arrow_right.png`);
  scene.load.image(`box_select`, `imgs/box_select.png`);
  scene.load.image(`box_select2`, `imgs/box_select2.png`);
  scene.load.image(`cancel`, `imgs/cancel.png`);
  scene.load.image(`clean`, `imgs/clean.png`);
  scene.load.image(`howto`, `imgs/howto.png`);
  scene.load.image(`howto_content`, `imgs/howtocontent.png`);
  scene.load.image(`menu_cursor`, `imgs/menu-cursor.png`);
  scene.load.image(`music`, `imgs/music.png`);
  scene.load.image(`music_player`, `imgs/music_player.png`);
  scene.load.image(`restart`, `imgs/restart.png`);
  scene.load.image(`select`, `imgs/select.png`);
  scene.load.image(`sound`, `imgs/sound.png`);
  scene.load.image(`box_sphere`, `imgs/box_sphere.png`);
  scene.load.image(`box_torus`, `imgs/box_torus.png`);
  scene.load.image(`box_triangle`, `imgs/box_triangle.png`);
  scene.load.image(`bricks`, `imgs/bricks.png`);
  scene.load.image(`metal`, `imgs/metal.png`);
}

const LoadingScene = wrapScene({
  initGame,
  createGame,
  updateGame,
  preloadGame,
})

export default LoadingScene;
